import { Dispatch, SetStateAction, useState } from 'react'

import { Search, SecondaryButton } from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { formatNameWithCode } from 'utils/formatters'
import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

import {
  getAccountId,
  getAccountProfileName,
  isLiteRetailer,
  userIsLoggedInAsAdmin,
  userIsOwner,
} from 'store/currentUser/selectors'

import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuOption from '../DropdownMenuOption/DropdownMenuOption'
import DropdownMenuOptionAccounts from '../DropdownMenuOptionAccounts/DropdownMenuOptionAccounts'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import MergeAccountsModal from './MergeAccountsModal/MergeAccountsModal'
import {
  useSetSelectedAccount,
  useUserAccounts,
} from './accountsDropdown.hooks'
import {
  RetailerInfo,
  useRetailerConnectedAccounts,
} from './accountsDropdown.queries'
import { AccountsDropdownIds } from 'features/NavbarRevamp/navbarRevamp.ids'
import {
  GrapheneAccount,
  IAccountsDropdownSelectors,
  MapRetailerIdsBrands,
  SetSelectedAccount,
} from 'features/NavbarRevamp/navbarRevamp.types'

const NavbarAccountsDropdownContainer = styled.div`
  display: flex;
  > div > div > .dropdownMenuGroup {
    border-radius: 0px 0px 4px 4px;
    border-right: 1px solid var(--primary-200, #cdd4da);
    border-bottom: 1px solid var(--primary-200, #cdd4da);
    border-left: 1px solid var(--primary-200, #cdd4da);
    background: var(--primary-100, #ebedf0);

    > .menuTextLink:not(:first-child) {
      border-top: none;
      padding-top: 0px;
    }
  }
`

const AccountLabel = styled.span`
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: contents;

  @media only screen and (max-width: 1200px) {
    max-width: 163px;
  }

  @media only screen and (max-width: 1100px) {
    max-width: 140px;
  }

  @media only screen and (max-width: 1024px) {
    max-width: 163px;
  }
`

const MenuBody = styled.div`
  max-height: 520px;
  max-width: 370px;
  overflow-y: auto;
`

const MenuBodyAccounts = styled.div`
  overflow-y: auto;
  max-height: 320px;

  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`

const SearchBar = styled.div`
  justify-content: center;
  color: var(--color-primary-600);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-tighter);
  > div {
    width: -moz-available; // Firefox
    width: -webkit-fill-available; // Chrome, Safari, Edge
  }
  input {
    text-align: left;
    font-size: 12px;
    font-weight: var(--font-weight-medium);
    font-family: var(--font-family-alpha);
    font-style: normal;
  }
`
const WrapperAccountOptionRetail = styled.div`
  border-bottom: 1px solid var(--color-primary-600);
  padding: 8px 0;
`
const WrapperAccountOptionDefault = styled.div`
  padding-bottom: 8px;

  &:last-child {
    padding-bottom: 0;
  }
`
const MergeButton = styled(SecondaryButton)`
  align-self: center;
  width: fit-content;
`

type AccountWithInfo = GrapheneAccount & {
  privacy: boolean
  isPrimary?: boolean
  createdAt?: string
}

const AccountOptions = ({
  accounts,
  setSelectedAccount,
  retailerIdsBrands = {},
  isLiteRetailAccount,
  currentAccountId,
  canMergeAccounts,
  setMergeModalOpen,
}: {
  accounts: AccountWithInfo[]
  setSelectedAccount: SetSelectedAccount
  retailerIdsBrands: MapRetailerIdsBrands
  isLiteRetailAccount: boolean
  currentAccountId: number
  canMergeAccounts: boolean
  setMergeModalOpen: Dispatch<SetStateAction<boolean>>
  retailersInfo: RetailerInfo[]
}): JSX.Element | null => {
  const [searchText, setSearchText] = useState<string>('')
  if (accounts.length < 2) {
    return null
  }
  const canSearchFilter =
    Boolean(searchText?.length) && Boolean(accounts.length)
  const search = canSearchFilter ? searchText.toLowerCase() : ''
  const AccountOption = isLiteRetailAccount
    ? WrapperAccountOptionRetail
    : WrapperAccountOptionDefault
  const DropdownMenuOptionComponent = isLiteRetailAccount
    ? DropdownMenuOptionAccounts
    : DropdownMenuOption

  return (
    <DropdownMenuGroup>
      <MenuBody>
        {isLiteRetailAccount && (
          <SearchBar>
            <Search
              data-testid={AccountsDropdownIds.AccountsSearchBar}
              placeholder={'Search by connected brands'}
              onSearch={({ searchText }: { searchText: string }) => {
                setSearchText(searchText)
              }}
              onKeyEnter={() => setSearchText(searchText)}
              value={searchText}
              incrementalSearch={true}
            />
          </SearchBar>
        )}
        <MenuBodyAccounts>
          {accounts.map(
            ({ name, id, privacy, createdAt, isPrimary }) =>
              (name.toLowerCase().includes(search) ||
                (isLiteRetailAccount
                  ? retailerIdsBrands[id]?.filter((brand) =>
                      brand?.profileName?.toLowerCase().includes(search),
                    )?.length > 0
                  : false)) && (
                <AccountOption
                  key={`${AccountsDropdownIds.AccountsOption}__${id}`}
                >
                  <DropdownMenuOptionComponent
                    message={formatNameWithCode(name, fromGlobalId(id).id)}
                    onClick={() => {
                      setSelectedAccount(id)
                    }}
                    id={`${AccountsDropdownIds.AccountsOption}__${id}`}
                    testId={`${AccountsDropdownIds.AccountsOption}__${id}`}
                    checked={
                      currentAccountId === parseInt(fromGlobalId(id).id || '')
                    }
                    connectedAccounts={
                      isLiteRetailAccount ? retailerIdsBrands[id] : []
                    }
                    privacy={!!privacy}
                    createdAt={createdAt ?? ''}
                    isPrimary={!!isPrimary}
                  />
                </AccountOption>
              ),
          )}
        </MenuBodyAccounts>
      </MenuBody>
      {canMergeAccounts && (
        <MergeButton onClick={() => setMergeModalOpen(true)}>
          Merge Duplicate Accounts
        </MergeButton>
      )}
    </DropdownMenuGroup>
  )
}

const NavbarAccountsDropdown = () => {
  const [isMergeModalOpen, setMergeModalOpen] = useState(false)
  const {
    accountId,
    accountName,
    isAccountOwner,
    isInternalUser,
    isLiteRetailAccount,
  } = useSelector<object, IAccountsDropdownSelectors>((state) => ({
    accountId: getAccountId(state),
    accountName: getAccountProfileName(state),
    isAccountOwner: userIsOwner(state),
    isInternalUser: userIsLoggedInAsAdmin(state),
    isLiteRetailAccount: isLiteRetailer(state),
  }))
  const userAccounts = useUserAccounts()
  const { setSelectedAccount } = useSetSelectedAccount()
  const { mergeAccounts } = useFlags()
  const hasAccountsToMerge =
    userAccounts.filter((account) => account.assortmentPlanning === false)
      ?.length >= 0
  const retailerIds = userAccounts.map((account) => account.id)
  const { retailerIdsBrands, retailersInfo } = useRetailerConnectedAccounts(
    retailerIds,
    isLiteRetailAccount,
  )

  const canMergeAccounts =
    isLiteRetailAccount &&
    ((isAccountOwner && mergeAccounts) || isInternalUser) &&
    hasAccountsToMerge

  // in this method i'm converting the id to another format because some of them
  // look like 'RetailerAccount:23423' decoded, and the other ones have this format: 'Retailer:23423'
  // also some of them are brands but we don't care about that, we just want to find retailers
  // in common in both backend queries (the one giving us the users + the one giving us extra info)
  const userAccountsWithInfo = userAccounts.map((account) => {
    const retailer = retailersInfo?.find(
      (retailer) =>
        retailer.id === toGlobalId('Retailer', fromGlobalId(account.id).id),
    )
    if (!!retailer) {
      return {
        ...account,
        ...retailer,
        id: toGlobalId('RetailerAccount', fromGlobalId(retailer?.id).id) || '',
      }
    }
    return account
  })

  return (
    <NavbarAccountsDropdownContainer>
      <NavbarDropdown
        rightPosition
        trigger={
          <Trigger
            label={
              <AccountLabel>
                {formatNameWithCode(accountName, accountId)}
              </AccountLabel>
            }
            id={AccountsDropdownIds.AccountsTrigger}
            withArrow={userAccounts.length > 1}
          />
        }
      >
        <AccountOptions
          accounts={userAccountsWithInfo}
          setSelectedAccount={setSelectedAccount}
          retailerIdsBrands={retailerIdsBrands}
          isLiteRetailAccount={isLiteRetailAccount}
          currentAccountId={accountId}
          canMergeAccounts={canMergeAccounts}
          setMergeModalOpen={setMergeModalOpen}
          retailersInfo={retailersInfo}
        />
        {canMergeAccounts && (
          <MergeAccountsModal
            isOpen={isMergeModalOpen}
            setOpen={setMergeModalOpen}
            accounts={userAccounts}
            accountId={String(accountId)}
          />
        )}
      </NavbarDropdown>
    </NavbarAccountsDropdownContainer>
  )
}

export default NavbarAccountsDropdown
