import { useQuery } from '@apollo/client'

import {
  fromGlobalId,
  relayConnectionToArray,
  toGlobalId,
} from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas, graphene } from 'graphql/tags'

import {
  BrandEdgeInfo,
  GraphNodeConnection,
  MapRetailerIdsBrands,
} from 'features/NavbarRevamp/navbarRevamp.types'

export const USER_ACCOUNTS = graphene`
  query userAccounts {
    viewer {
      id
      accounts {
        edges {
          node {
            ... on BrandAccount {
              id
              name
              }
            ... on RetailerAccount {
              id
              name
              displayName
              assortmentPlanning
              }
            ... on AdminAccount {
              id
              name
            }
          }
        }
      }
    }
  }
`

const RETAIL_CONNECTED_ACCOUNTS = atlas`#graphql
 query LiteRetailerConnectedAccountsDropdown ($retailerIds: [ID!]!) {
   retailers (retailerIds:$retailerIds) {
     edges{
       node{
         id
         displayName
         name
         isPrimary
         createdAt
         privacy
         brands(allConnections:true){
           edges{
             id
             profileName
             node {
               id
             }
           }
         }
       }
     }
   }
 }
`

export type RetailerInfo = {
  id: string
  privacy: boolean
  isPrimary: boolean
  createdAt: string
}

export const useRetailerConnectedAccounts = (
  retailerIds: string[],
  isLiteRetailAccount: boolean,
): {
  retailerIdsBrands: MapRetailerIdsBrands | {}
  retailersInfo: RetailerInfo[]
} => {
  const { data } = useQuery(RETAIL_CONNECTED_ACCOUNTS, {
    client: atlasClient,
    skip: !isLiteRetailAccount,
    variables: {
      retailerIds,
    },
  })
  const retailerIdsBrands: { [key: string]: BrandEdgeInfo[] | undefined } = {}
  data?.retailers?.edges?.forEach((retailer: GraphNodeConnection) => {
    const retailerid =
      toGlobalId('RetailerAccount', fromGlobalId(retailer?.node?.id).id) ?? ''
    retailerIdsBrands[retailerid] = retailer?.node?.brands?.edges
  })

  return {
    retailerIdsBrands: retailerIdsBrands || {},
    retailersInfo: relayConnectionToArray(data?.retailers).map(
      (account: {
        id: string
        privacy: boolean
        isPrimary: boolean
        createdAt: string
      }) => {
        return {
          id: account.id,
          privacy: account.privacy,
          createdAt: account.createdAt,
          isPrimary: account.isPrimary,
        }
      },
    ),
  }
}
