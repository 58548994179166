import { Icon, Tooltip } from '@joor/design-system'
import styled from 'styled-components'

import { LGConnectionStatus } from '__generated__/atlas-types'

import People from '../People/People'
import SectionHeader from '../SectionHeader'
import { SubmissionLink } from '../SubmissionLink/SubmissionLink'
import BasicInfoViewMode from 'features/LeadGen/RetailerProfile/RetailerProfileViewMode/components/BasicInfoViewMode/BasicInfoViewMode'
import LocationWidgetViewMode from 'features/LeadGen/RetailerProfile/RetailerProfileViewMode/components/LocationWidgetViewMode/LocationWidgetViewMode'
import {
  Container,
  StyledHeader as StyledLocationHeader, // eslint-disable-next-line max-len
} from 'features/LeadGen/RetailerProfile/RetailerProfileViewMode/components/LocationWidgetViewMode/LocationWidgetViewMode.styles'
import ProfileImagesViewMode from 'features/LeadGen/RetailerProfile/RetailerProfileViewMode/components/ProfileImagesViewMode/ProfileImagesViewMode'
import { ViewModeIds } from 'features/LeadGen/RetailerProfile/retailerProfilePage.ids'
import {
  RetailerProfile,
  RetailerProfileLocation,
} from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 48px;
  gap: 32px;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    > *:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
const MoreLocationsMessage = styled.div`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  letter-spacing: 0.6px;
  color: var(--color-primary-300);
  margin-top: 16px;
`
const EmptyLocation = styled.div`
  width: 100%;
  margin: 50px;
  @media only screen and (min-width: 1200px) {
    margin: 0 32px;
  }
`
const StyledDescriptionTitle = styled.div`
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-regular);
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 1.2px;
  color: var(--color-primary-700);
  border-bottom: 1px solid var(--color-primary-300);
  width: calc(100% - 64px);
  margin: 0 32px 24px;
  padding-bottom: 8px;
`
type RetailerProfileContentProps = {
  retailer: RetailerProfile
  isRetailerAccount: boolean
}
const RetailerProfileContent = ({
  retailer,
  isRetailerAccount,
}: RetailerProfileContentProps) => {
  const profileImages = retailer?.profileImages
    ?.map((profileImage) => profileImage?.upload?.url)
    .filter(Boolean) as string[]

  const locationsToMap = (retailer?.primaryLocation
    ? [
        {
          ...retailer.primaryLocation,
          isPrimary: true,
        } as RetailerProfileLocation,
      ]
    : []
  ).concat(retailer?.locations ?? [])

  return (
    <div>
      <StyledDescriptionTitle>About Us</StyledDescriptionTitle>
      <StyledRow>
        <BasicInfoViewMode
          description={retailer?.description ?? ''}
          socialMedia={retailer?.socialMediaAccounts ?? []}
          website={retailer?.websiteUrl ?? ''}
        />
        <ProfileImagesViewMode imageUrls={profileImages ?? []} />
      </StyledRow>
      <StyledRow>
        <Container>
          <SectionHeader>
            <span
              id={
                isRetailerAccount
                  ? ViewModeIds.PeopleWidget.SectionTitle.Retailer
                  : ViewModeIds.PeopleWidget.SectionTitle.Brand
              }
            >
              People
            </span>
            {retailer.people &&
            retailer.people?.length > 0 &&
            retailer.accountSubmission?.messageId ? (
              <Tooltip
                defaultPlacement="right"
                content={
                  <SubmissionLink
                    submissionSent={{
                      messageId: retailer.accountSubmission.messageId,
                      date: retailer.accountSubmission.createdAt,
                      rep:
                        retailer.accountSubmission.senderAccount
                          ?.accountUserName ||
                        retailer.accountSubmission.senderAccount?.accountName ||
                        '',
                    }}
                  />
                }
              >
                <Icon iconName="information" iconSize="regular" />
              </Tooltip>
            ) : (
              ''
            )}
          </SectionHeader>
          <People
            people={retailer.people}
            buyer={retailer.buyer}
            isRetailerConnected={
              retailer.connectionStatus === LGConnectionStatus.CONNECTED
            }
            isRetailer={!!isRetailerAccount}
            retailerId={retailer.id}
            accountSubmission={retailer.accountSubmission}
          />
        </Container>
      </StyledRow>
      {locationsToMap?.map(
        (location: RetailerProfileLocation, index: number) => (
          <StyledRow key={`${index}_${location.id}`}>
            <Container>
              <StyledLocationHeader>
                {index <= 1 &&
                  (location?.isPrimary
                    ? 'Primary Location'
                    : 'Other Locations')}
              </StyledLocationHeader>
              <LocationWidgetViewMode location={location} />
            </Container>
          </StyledRow>
        ),
      )}
      <StyledRow>
        {(retailer?.locations?.length || 0) === 0 && (
          <EmptyLocation>
            <StyledLocationHeader>
              {isRetailerAccount ? 'Other Locations' : 'Locations'}
            </StyledLocationHeader>
            <MoreLocationsMessage>
              {isRetailerAccount
                ? 'Edit page to add additional locations'
                : 'The retailer has not provided any information about locations.'}
            </MoreLocationsMessage>
          </EmptyLocation>
        )}
      </StyledRow>
    </div>
  )
}

export default RetailerProfileContent
