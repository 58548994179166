import { NAVBAR_REVAMP_PREFIX as FEATURE_PREFIX } from 'featurePrefix.ids'

const ACCOUNTS_PREFIX = 'accounts'
const CART_PREFIX = 'cart'
const CONNECTIONS_PREFIX = 'connections'
const DISCOVER_PREFIX = 'discover'
const DROPDOWN_OPTION_PREFIX = 'dropdown-option'
const HAMBURGER_PREFIX = 'hamburger'
const HOME_PREFIX = 'home'
const PRO_LITE_INDICATOR_PREFIX = 'pro-lite-indicator'
const LANGUAGES_PREFIX = 'languages'
const MESSAGES_PREFIX = 'messages'
const ORDERS_PREFIX = 'orders'
const PASSPORT_PREFIX = 'passport'
const PAYMENTS_PREFIX = 'payments'
const PRODUCTS_PREFIX = 'products'
const PROFILE_PREFIX = 'profile'
const REPORTING_PREFIX = 'reporting'
const REPORTS_PREFIX = 'reports'
const SETTINGS_PREFIX = 'settings'
const SHOP_PREFIX = 'shop'
const TRIGGER_PREFIX = 'trigger'
const WORKSPACES_PREFIX = 'workspaces'

export const OrdersDropdownIds = {
  OrdersTrigger: `${FEATURE_PREFIX}__${ORDERS_PREFIX}__${TRIGGER_PREFIX}`,
  OrdersOptionOrders: `${FEATURE_PREFIX}__${ORDERS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__orders`,
  OrdersOptionStartOrder: `${FEATURE_PREFIX}__${ORDERS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__start-orders`,
  OrdersOptionUploadExcel: `${FEATURE_PREFIX}__${ORDERS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__upload-excel`,
  OrdersProFeature: `${FEATURE_PREFIX}__${ORDERS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__pro-feature`,
  OrdersProFeatureLearnMore: `${FEATURE_PREFIX}__${ORDERS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__pro-feature-learn`,
} as const

export const PaymentsDropdownIds = {
  PaymentTrigger: `${FEATURE_PREFIX}__${PAYMENTS_PREFIX}__${TRIGGER_PREFIX}`,
  PaymentsOptionsManageJoorPayOrders: `${FEATURE_PREFIX}__${PAYMENTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__manage_joor_pay_orders`,
  PaymentsOptionsExternalPayments: `${FEATURE_PREFIX}__${PAYMENTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__external_payments`,
  PaymentsOptionsJOORPayButton: `${FEATURE_PREFIX}__${PAYMENTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__joor_pay__button`,
  PaymentsOptionsJOORPayPromo: `${FEATURE_PREFIX}__${PAYMENTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__joor_pay_text`,
}

export const ConnectionsDropdownIds = {
  ConnectionIconTrigger: `${FEATURE_PREFIX}__${CONNECTIONS_PREFIX}__${TRIGGER_PREFIX}__icon`,
  ConnectionOptionsFindRetailers: `${FEATURE_PREFIX}__${CONNECTIONS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__find_retailers`,
  ConnectionOptionsManage: `${FEATURE_PREFIX}__${CONNECTIONS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__manage`,
  ConnectionOptionsMyConnections: `${FEATURE_PREFIX}__${CONNECTIONS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__my_connections`,
  ConnectionOptionsRequests: `${FEATURE_PREFIX}__${CONNECTIONS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__requests`,
  ConnectionOptionsPending: `${FEATURE_PREFIX}__${CONNECTIONS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__pending_connections`,
  ConnectionTrigger: `${FEATURE_PREFIX}__${CONNECTIONS_PREFIX}__${TRIGGER_PREFIX}`,
}
export const ProductsDropdownIds = {
  ProductsTrigger: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${TRIGGER_PREFIX}`,
  ProductsOptionsCreateLinesheet: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__create-linesheet`,
  ProductsOptionsCreateLook: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__create-look`,
  ProductsOptionsCreateStyle: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__create-style`,
  ProductsOptionsCreateStyleboard: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__create-styleboard`,
  ProductsOptionsInventory: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__inventory`,
  ProductsOptionsManageLinesheets: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__manage-linesheets`,
  ProductsOptionsManageLooks: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__manage-looks`,
  ProductsOptionsManageStaging: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__staging`,
  ProductsOptionsManageStyleSync: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__style-sync`,
  ProductsOptionsManageStyleboards: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__manage-styleboards`,
  ProductsOptionsManageStyles: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__manage-styles`,
  ProductsOptionsUploadData: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__upload-data`,
  ProductsOptionsUploadStyle: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__upload-style`,
  ProductsOptionsUploadImages: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__upload-images`,
  ProductsOptionsBasicStyleUploader: `${FEATURE_PREFIX}__${PRODUCTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__basic-style-uploader`,
}
export const ProfileDropdownIds = {
  ProfileOptionsAboutAssets: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${DROPDOWN_OPTION_PREFIX}__about_assets`,
  ProfileOptionsJOORUrl: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${DROPDOWN_OPTION_PREFIX}__joor_url`,
  ProfileOptionsLookbooks: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${DROPDOWN_OPTION_PREFIX}__lookbooks`,
  ProfileOptionsManage: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${DROPDOWN_OPTION_PREFIX}__manage`,
  ProfileOptionsview: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${DROPDOWN_OPTION_PREFIX}__view`,
  ProfileOptionsPhotoGallery: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${DROPDOWN_OPTION_PREFIX}__photo_gallery`,
  ProfileOptionsPress: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${DROPDOWN_OPTION_PREFIX}__press`,
  ProfileOptionsViews: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${DROPDOWN_OPTION_PREFIX}__profile_views`,
  ProfileOptionsProfile: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${DROPDOWN_OPTION_PREFIX}__profile`,
  ProfileTrigger: `${FEATURE_PREFIX}__${PROFILE_PREFIX}__${TRIGGER_PREFIX}`,
}

export const ReportsDropdownIds = {
  ReportsTrigger: `${FEATURE_PREFIX}__${REPORTS_PREFIX}__${TRIGGER_PREFIX}`,
  ReportsOptionSalesSummary: `${FEATURE_PREFIX}__${REPORTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__sales_summary`,
  ReportsOptionDelivery: `${FEATURE_PREFIX}__${REPORTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__delivery_report`,
  ReportsOptionStyleDetail: `${FEATURE_PREFIX}__${REPORTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__style_detail`,
  ReportsOptionProductionReport: `${FEATURE_PREFIX}__${REPORTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__production_report`,
  ReportsOptionCustomReports: `${FEATURE_PREFIX}__${REPORTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__custom_reports`,
  ReportsOptionJOORPayReports: `${FEATURE_PREFIX}__${REPORTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__JOORPay_reports`,
  ReportsOptionClassicReports: `${FEATURE_PREFIX}__${REPORTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__classic_reports`,
  ReportsOptionProFeature: `${FEATURE_PREFIX}__${ORDERS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__pro-feature`,
  ReportsOptionProFeatureLearnMore: `${FEATURE_PREFIX}__${ORDERS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__pro-feature-learn`,
}
export const AccountsDropdownIds = {
  AccountsTrigger: `${FEATURE_PREFIX}__${ACCOUNTS_PREFIX}__${TRIGGER_PREFIX}`,
  AccountsOption: `${FEATURE_PREFIX}__${ACCOUNTS_PREFIX}__${DROPDOWN_OPTION_PREFIX}`,
  AccountsSearchBar: `${FEATURE_PREFIX}__${ACCOUNTS_PREFIX}__search-bar`,
}
export const LanguagesDropdownIds = {
  LanguagesTrigger: `${FEATURE_PREFIX}__${LANGUAGES_PREFIX}__${TRIGGER_PREFIX}`,
  LanguagesOptions: `${FEATURE_PREFIX}__${LANGUAGES_PREFIX}__${DROPDOWN_OPTION_PREFIX}`,
}

export const CartDropdownIds = {
  CartBadge: `${FEATURE_PREFIX}__${CART_PREFIX}__badge`,
  CartTrigger: `${FEATURE_PREFIX}__${CART_PREFIX}__${TRIGGER_PREFIX}`,
  CartView: `${FEATURE_PREFIX}__${CART_PREFIX}__view`,
  CartLink: `${FEATURE_PREFIX}__${CART_PREFIX}__link`,
  CartContainer: `${FEATURE_PREFIX}__${CART_PREFIX}__container`,
}
export const DiscoverDropdownIds = {
  DiscoverTrigger: `${FEATURE_PREFIX}__${DISCOVER_PREFIX}__${TRIGGER_PREFIX}`,
  DiscoverOptionFindNewBrands: `${FEATURE_PREFIX}__${DISCOVER_PREFIX}__${DROPDOWN_OPTION_PREFIX}__find_new_brands`,
  DiscoverOptionPassport: `${FEATURE_PREFIX}__${DISCOVER_PREFIX}__${DROPDOWN_OPTION_PREFIX}__passport`,
}
export const SettingsDropdownIds = {
  SettingsOptionAttributeSettings: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__attribute_settings`,
  SettingsOptionHelp: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__help_center`,
  SettingsOptionIntegrations: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__integrations`,
  SettingsOptionInventory: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__inventory`,
  SettingsOptionKellwood: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__Kellwood`,
  SettingsOptionLogout: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__logout`,
  SettingsOptionMySettings: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__my_settings`,
  SettingsOptionOrderSettings: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__order_settings`,
  SettingsOptionPayment: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__payment_settings`,
  SettingsOptionPermissions: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__permission_settings`,
  SettingsOptionRLM: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__RLM`,
  SettingsOptionRetailerSettings: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__retailer_settings`,
  SettingsOptionSSO: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}_sso`,
  SettingsOptionStyleSettings: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__style_settings`,
  SettingsOptionStyleSync: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__style_sync`,
  SettingsOptionSwitchTo: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__switch_to`,
  SettingsOptionThirdParty: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${DROPDOWN_OPTION_PREFIX}__third_party`,
  SettingsTrigger: `${FEATURE_PREFIX}__${SETTINGS_PREFIX}__${TRIGGER_PREFIX}`,
}
export const MessagesOptionIds = {
  MessagesTrigger: `${FEATURE_PREFIX}__${MESSAGES_PREFIX}__${TRIGGER_PREFIX}`,
  MessagesChatIcon: `${FEATURE_PREFIX}__${MESSAGES_PREFIX}__${TRIGGER_PREFIX}__chat-icon`,
}
export const PassportOptionIds = {
  PassportTrigger: `${FEATURE_PREFIX}__${PASSPORT_PREFIX}__${TRIGGER_PREFIX}`,
}
export const HamburgerOptionIds = {
  HamburgerTrigger: `${FEATURE_PREFIX}__${HAMBURGER_PREFIX}__${TRIGGER_PREFIX}`,
}

export const ShopDropdownIds = {
  ShopTrigger: `${FEATURE_PREFIX}__${SHOP_PREFIX}__${TRIGGER_PREFIX}`,
  ShopOptionLooks: `${FEATURE_PREFIX}__${SHOP_PREFIX}__${DROPDOWN_OPTION_PREFIX}__looks`,
  ShopOptionBrowseCatalog: `${FEATURE_PREFIX}__${SHOP_PREFIX}__${DROPDOWN_OPTION_PREFIX}__browse_catalog`,
  ShopOptionCreateStyleboard: `${FEATURE_PREFIX}__${SHOP_PREFIX}__${DROPDOWN_OPTION_PREFIX}__create_styleboard`,
  ShopOptionStyleBoards: `${FEATURE_PREFIX}__${SHOP_PREFIX}__${DROPDOWN_OPTION_PREFIX}__styleboards`,
  ShopOptionLinesheets: `${FEATURE_PREFIX}__${SHOP_PREFIX}__${DROPDOWN_OPTION_PREFIX}__linesheets`,
  ShopOptionStartOrder: `${FEATURE_PREFIX}__${SHOP_PREFIX}__${DROPDOWN_OPTION_PREFIX}__start_order`,
}

export const HomeIds = {
  HomeLinkTrigger: `${FEATURE_PREFIX}__${HOME_PREFIX}__${TRIGGER_PREFIX}`,
}

export const ProLiteIndicatorIds = {
  ProLiteIndicatorText: `${FEATURE_PREFIX}__${PRO_LITE_INDICATOR_PREFIX}__text`,
}

export const WorkspacesOptionIds = {
  WorkspacesTrigger: `${FEATURE_PREFIX}__${WORKSPACES_PREFIX}__${TRIGGER_PREFIX}`,
  WorkspacesOption: `${FEATURE_PREFIX}__${WORKSPACES_PREFIX}__${DROPDOWN_OPTION_PREFIX}__workspaces`,
  BrandNotesOption: `${FEATURE_PREFIX}__${WORKSPACES_PREFIX}__${DROPDOWN_OPTION_PREFIX}__brand_notes`,
  ArchivedOption: `${FEATURE_PREFIX}__${WORKSPACES_PREFIX}__${DROPDOWN_OPTION_PREFIX}__archived`,
}

export const ReportingOptionIds = {
  ReportingTrigger: `${FEATURE_PREFIX}__${REPORTING_PREFIX}__${TRIGGER_PREFIX}`,
}
